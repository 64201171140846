<template>
  <div class="d-flex df-row dim">
    <div class="spacer"></div>
    <div class="d-flex df-col terms-container">
      <div class="terms-inner d-flex df-col">
        <div class="terms-header d-flex df-row">
          <img
            :src="require('@/assets/images/accounts/bi-typo.svg')"
            class="bi-typo"
          />
          <div class="spacer"></div>
          <img
            :src="require('@/assets/images/accounts/btn-close.svg')"
            class="close-btn cursor"
            @click="$emit('termsClose')"
          />
        </div>
        <div class="terms-body d-flex df-col">
          <h3 class="terms-title">{{ title }}</h3>
          <div class="divider" />
          <div class="terms-contents-wrapper d-flex df-col">
            <perfect-scrollbar>
              <div v-html="contents" class="terms-contents"></div>
            </perfect-scrollbar>
          </div>
        </div>
      </div>
    </div>
    <div class="spacer"></div>
  </div>
</template>

<script>
import {PerfectScrollbar} from "vue2-perfect-scrollbar";

export default {
  props: {
    title: {
      type: String,
      require: true
    },
    contents: {
      type: String,
      require: true
    }
  },
  mounted() {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty("--height", `${vh}px`);

    window.addEventListener("resize", () => this.resizeHeight);
  },
  methods: {
    resizeHeight() {
      let vh = window.innerHeight * 0.01;
      document.documentElement.style.setProperty("--height", `${vh}px`);
    }
  },
  components: {
    PerfectScrollbar
  }
};
</script>

<style lang="scss">
.bi-typo {
  width: fit-content;
  height: 16px;
}
.close-btn {
  width: 24px;
  height: 24px;
  margin-top: -5px;
}
.terms-title {
  font-size: 20px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
}
.divider {
  width: 100%;
  height: 1px;
  background: #f1f1f1;
  margin: 16px 0;
}
.terms-contents {
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  letter-spacing: -0.005em;
  text-align: left;
  max-width: 96%;
}
@media screen and (min-width: 720px) {
  .terms-container {
    position: relative;
    width: 56%;
    height: 85%;
    background: #fff;
    border-radius: 8px;
    margin: auto;
    box-sizing: border-box;
  }
  .terms-inner {
    height: 100%;
    padding: 66px 48px 32px;
  }
  .terms-body {
    position: relative;
    height: 100%;
    padding: 28px 0 16px 0;
    box-sizing: border-box;
  }
  .terms-contents-wrapper {
    position: absolute;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
  }
}
@media screen and (max-width: 719px) {
  .terms-container {
    width: 100%;
    height: 100%;
    background: #fff;
    box-sizing: border-box;
  }
  .terms-inner {
    padding: 32px 12px 12px;
  }
  .terms-body {
    padding: 32px 0 16px 0;
  }
  .terms-contents-wrapper {
    height: 100%;
  }
  .terms-contents {
    //height: calc(100vh - 150px);
    height: calc(var(--height, 1vh) * 100 - 150px);
  }
}
</style>
