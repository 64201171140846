var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex df-row dim"
  }, [_c('div', {
    staticClass: "spacer"
  }), _c('div', {
    staticClass: "d-flex df-col terms-container"
  }, [_c('div', {
    staticClass: "terms-inner d-flex df-col"
  }, [_c('div', {
    staticClass: "terms-header d-flex df-row"
  }, [_c('img', {
    staticClass: "bi-typo",
    attrs: {
      "src": require('@/assets/images/accounts/bi-typo.svg')
    }
  }), _c('div', {
    staticClass: "spacer"
  }), _c('img', {
    staticClass: "close-btn cursor",
    attrs: {
      "src": require('@/assets/images/accounts/btn-close.svg')
    },
    on: {
      "click": function click($event) {
        return _vm.$emit('termsClose');
      }
    }
  })]), _c('div', {
    staticClass: "terms-body d-flex df-col"
  }, [_c('h3', {
    staticClass: "terms-title"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "divider"
  }), _c('div', {
    staticClass: "terms-contents-wrapper d-flex df-col"
  }, [_c('perfect-scrollbar', [_c('div', {
    staticClass: "terms-contents",
    domProps: {
      "innerHTML": _vm._s(_vm.contents)
    }
  })])], 1)])])]), _c('div', {
    staticClass: "spacer"
  })]);
}
var staticRenderFns = []

export { render, staticRenderFns }